import IAddress, { Address } from '../Models/Address';
import IVerifyAddressRequest from '../Models/jena/request/IVerifyAddressRequest';
import { TransportAlternativeRequest } from '../Models/jena/request/TransportAlternativeRequest';
import { Parcel } from '../Models/Parcel';
import { DeliveryContext } from '../Pages/Delivery/deliveryMachine';
import { GetCustomerTransportInfoResponse } from '../Models/jena/response/GetCustomerTransportInfoResponse';
import { PartnerInformation } from '../Models/PartnerInformation';
import { Currency } from '../enums/Currency';
import { SendPackageContext } from '../Machines/sendPackageMachine';
import { CreateAwbRequest } from '../Models/jena/request/CreateAwbRequest';
import {
  BookDefAdvise,
  BookDefBase,
  BookDefCard,
  BookDefConsignee,
  BookDefConsignment,
  BookDefCustoms,
  BookDefInvoice,
  BookDefRequest,
  BookDefShipper,
  BookDefUser,
  PaymentMethodId,
} from '../Models/jena/request/BookDefRequest';
import { LoginResponse } from '../Models/jena/response/LoginResponse';
import { User } from '../Models/User';
import {
  GetCustomerInfoAddress,
  GetCustomerInfoResponse,
} from '../Models/jena/response/GetCustomerInfoResponse';
import { ListCountry } from '../Models/jena/response/GetCountriesResponse';
import {
  GetShopResponse,
  isShop,
} from '../Models/jena/response/GetShopResponse';
import { Shop } from '../Models/Shop';
import { SaveRegistrationRequest } from '../Models/jena/request/SaveRegistrationRequest';
import { IRegisterContext } from '../Pages/Register/RegisterMachine';
import { sortByAddress } from '../helpers/addressHelper';
import { SaveWebAddressRequest } from '../Models/jena/request/SaveWebAddressRequest';
import { ISaveAddressContext } from '../Components/SaveAddress/SaveAddressMachine';
import { CreateGetCityInfoRequest } from '../Models/jena/request/GetCityInfo';
import { GetCustomerTransportInfoRequest } from '../Models/jena/request/GetcustomerTransportInfoRequest';
import { TLanguageCode } from '../translations/TranslationType';
import {
  ListCampaign,
  ListCampaignAlternative,
  VerifyCampaignCodeResponse,
} from '../Models/jena/response/VerifyCampaignCodeResponse';
import { Campaign } from '../Models/Campaign';
import { SaveCustomsRequest } from '../Models/jena/request/SaveCustomsRequest';
import {
  JenaPackagetype,
  Packagetype,
} from '../Models/jena/request/ListPackageTypesResponse';
import { Translation } from '../Models/jena/Translation';
import { ListAddressResponse } from '../Models/jena/response/ListAddressResponse';
import fileToBase64 from '../helpers/fileToBase64';
import SaveUploadFileRequest from '../Models/jena/request/SaveUploadFileRequest';
import {
  ConsignmentResponse,
  ListConsignmentResponse,
  OrderHistoryRows,
} from '../Models/jena/response/ListConsignmentResponse';
import { ShowBookingInfoResponse } from '../Models/jena/response/ShowBookingInfoResponse';

export const verifyAddressRequest = (
  from: IAddress,
  to: IAddress
): IVerifyAddressRequest => ({
  fromcityname: from.city,
  fromcountryname: from.countryCode || '',
  fromstreetname: from.street,
  fromstreetnbr: from.streetNumber,

  tocityname: to.city,
  tocountryname: to.countryCode || '',
  tostreetname: to.street,
  tostreetnbr: to.streetNumber,

  fromcountryrule: 1,
  tocountryrule: 1,
  custorgid: 0,
  custpersonid: 0,
  is_api: 1,
});

export const contextToDeliveryAlternatives = (
  context: DeliveryContext,
  user?: User
): TransportAlternativeRequest => {
  const packages = context.packages
    .reduce((ret: Parcel[], parcel: Parcel) => {
      // api doesnt handle multiples of the same package, each need their own row
      /* EJ 20230306 Vikten multipliceras med antal kollin
        Om man bokar 2 kli 5 kg så brukar detta betyda 2 kli på vardera 2,5 kg (om man inte anger annat. Nu blir detta 2 kli 10 kg.
          Se bild 1 och 2. AWB JET-11450689. Det ser också lite konstigt ut att det står Paket 1, 2 st, 5 kg i sammanfattningen.
          Antingen borde alla paket visas (Paket 1, 1 st, 2,5 kg och Paket 2, 1 st, 2,5 kg), men eftersom Ett paket bara kan vara ett kolli
          så borde det antingen stå Paket, 2 st, 5 kg, eller Paket 1, 2,5 kg, Paket 2, 2,5 kg).
      */
      for (let i = 0; i < parcel.quantity; i++) {
        if (parcel.quantity > 1) {
          const weight: number =
            Math.round((parcel.weight! / parcel.quantity) * 100) / 100;
          ret.push({ ...parcel, weight });
        } else {
          ret.push(parcel);
        }
      }
      return ret;
    }, [])
    .reduce((ret, parcel, i) => {
      return {
        ...ret,
        [`PAC${i}_PACKAGETYPE`]: '' + parcel.parcelType?.packagetypeid,
        [`PAC${i}_ESTIMATEDHEIGHT`]: '' + parcel.height,
        [`PAC${i}_ESTIMATEDLENGTH`]: '' + parcel.length,
        [`PAC${i}_ESTIMATEDWEIGHT`]: '' + parcel.weight,
        [`PAC${i}_ESTIMATEDWIDTH`]: '' + parcel.width,
      };
    }, {});
  return {
    ...packages,
    Consignee_City: context.consignee?.city || '',
    Consignee_Country: context.consignee?.countryCode || '',
    Consignee_Street: context.consignee?.street || '',
    Consignee_StreetNbr: context.consignee?.streetNumber || '',
    // MF 20230116 Just nu saknas innehåll på sändningen från nya webbook när man ska ta sig till kortbetalningen. För att komma vidare just nu kan du lägga till hårdkodat "Contents" när du anropar GetTrpAlt (dokumentation uppdaterad).
    Contents: 'Package',
    MaxAlternatives: 4,
    RequestType: context.requestType,
    Shipper_City: context.shipper?.city || '',
    Shipper_Country: context.shipper?.countryCode || '',
    Shipper_Street: context.shipper?.street || '',
    Shipper_StreetNbr: context.shipper?.streetNumber || '',
    TransportDate: context.date || '',
    TransportTime: context.time || '',
    TransportEndCloseTime: context.timeEndClose,
    TransportEndDate: context.dateEnd,
    TransportEndOpenTime: context.timeEndOpen,
    TransportStartCloseTime: context.timeStartClose,
    TransportStartDate: context.dateStart,
    TransportStartOpenTime: context.timeStartOpen,
    UserId: user?.id || '',
    JenaApplicationType: 2,
  };
};

export const customerTransportInfoToPartnerInfo = (
  response: GetCustomerTransportInfoResponse
): PartnerInformation => ({
  partnerCompId: response.pcompid,
  cardCheckUrl: response.cardcheckurl,
  useInsurance: response.useinsurance === '1',
  timeZone: response.tzname,
  webSupportNumber: response.websupportnumber,
  currency: Currency[response.currency_name],
  reportUrl: '',
  currencyId: response.currencyid,
});

export const pickupaddressToAddress = (
  data: ShowBookingInfoResponse
): Address => ({
  id: data.pickupaddress.addressid,
  city: data.pickupaddress.city,
  country: data.pickupaddress.countryname,
  countryId: data.pickupaddress.countryid,
  cityId: data.pickupaddress.cityservedid,
  street: data.pickupaddress.streetaddress,
  streetNumber: data.pickupaddress.streetnbr,
  countryCode: data.pickupaddress.isocode,
  postCode: data.pickupaddress.postcode,
  addressName: data.pickupaddress.name,
  firstName: data.consignment.pickupcontactfname,
  lastName: data.consignment.pickupcontactsname,
  phone: data.consignment.pickupphone1,
});

export const deliveryaddressToAddress = (
  data: ShowBookingInfoResponse
): Address => ({
  id: data.deliveryaddress.addressid,
  city: data.deliveryaddress.city,
  country: data.deliveryaddress.countryname,
  countryId: data.deliveryaddress.countryid,
  cityId: data.deliveryaddress.cityservedid,
  street: data.deliveryaddress.streetaddress,
  streetNumber: data.deliveryaddress.streetnbr,
  countryCode: data.deliveryaddress.isocode,
  postCode: data.deliveryaddress.postcode,
  addressName: data.deliveryaddress.name,
  firstName: data.consignment.deliverycontactfname,
  lastName: data.consignment.deliverycontactsname,
  phone: data.consignment.deliveryphone1,
});

const countryToCountryId = (tld: string) => {
  switch (tld) {
    case 'fi':
      return 26;
    case 'no':
      return 66;
    case 'dk':
      return 23;
    case 'se':
      return 1;
    default:
      return 12; // default to Belgium
  }
};

const tldToLanguage = (tld: string): TLanguageCode => {
  switch (tld) {
    case 'fi':
      return 'fi-FI';
    case 'no':
      return 'nb-NO';
    case 'dk':
      return 'da-DK';
    case 'se':
    case 'localhost':
      return 'sv-SE';
    default:
      return 'en-GB'; // default to English
  }
};

export const countryToLanguage = (country?: string): TLanguageCode => {
  switch (country?.toLocaleLowerCase()) {
    case 'fi':
      return 'fi-FI';
    case 'no':
      return 'nb-NO';
    case 'dk':
      return 'da-DK';
    case 'se':
      return 'sv-SE';
    default:
      return 'en-GB'; // default to English
  }
};

export const getUserCountryId = (): number => {
  const m = window.location.pathname.match(/^\/(se|no|fi|dk)/);
  const country = m ? m[1] : 'en';
  const countryid = countryToCountryId(country);
  return countryid;
};

export const getUserLanguage = (): TLanguageCode => {
  return tldToLanguage(window.location.hostname.split('.').pop() || '');
};

export const customerTransportInfoRequest =
  (): GetCustomerTransportInfoRequest => {
    const countryid = getUserCountryId();
    return {
      checkcredit: 1,
      fromcountryid: countryid,
      tocountryid: countryid,
    };
  };

export const contextToCreateAwbRequest = (
  context: SendPackageContext
): CreateAwbRequest => ({
  consignmentid: context.deliveryOption?.ConsignmentId || '', //throw new Error('Missing consignment ID'),
  is_courier: context.deliveryOption!.MethodOfTransport === 'Courier' ? 1 : 0,
  fromcityid: context.shipper.cityId,
  tocityid: context.consignee.cityId,
  pathfinderresultid: context.deliveryOption!.AlternativeId,
});

export const createGetCityInfoRequest = (
  countryId: number,
  postalCode: string
): CreateGetCityInfoRequest => ({
  countryid: countryId,
  country_rule: 1,
  name: postalCode,
});

export const registerContextToSaveRegistrationRequest = (
  context: IRegisterContext
): SaveRegistrationRequest => {
  if (!context.partnerInfo) throw new Error('Missing context.partnerInfo');
  return {
    partnercompid: context.partnerInfo?.partnerCompId, // '22',
    customer_customertype: context.customerType === 'private' ? '1' : '2',
    customerperson_firstname: context.firstName,
    customerperson_lastname: context.lastName,
    contactmethod_email: context.email,
    contactmethod_phone1: context.phone,
    contactmethod_phone2: context.phone2,
    ...(context.orgId // if existing
      ? { customerorganization_custorgid: context.orgId }
      : {
          invoiceaddress_streetaddress: context.street,
          invoiceaddress_streetnbr: context.streetNumber,
          invoiceaddress_postcode: parseInt(
            context.postalCode.replaceAll(' ', '')
          ),
          invoiceaddress_city: context.city,
          invoiceaddress_countryid: context.country,
        }),
    ...(context.customerType === 'company'
      ? {
          customerorganization_organizationnbr: context.orgNumber.replace(
            '-',
            ''
          ),
          customerorganization_name: context.orgName,
          customerorganization_vatnumber: context.vatNumber,
          customeracceptinvoice: '1',
        }
      : {}),
  };
};

export const addressContextToSaveAddressRequest = (
  context: ISaveAddressContext,
  customerNumber?: string
): SaveWebAddressRequest => {
  return {
    id: context.id ? Number(context.id) : undefined,
    addressname: context.addressName,
    city: context.city,
    contactpersonfname: context.firstName,
    contactpersonsname: context.lastName,
    country: Number(context.countryId),
    postcode: context.postCode,
    street: context.street,
    streetnbr: context.streetNumber,
    phone: context.phone,
    customernbr: Number(customerNumber),
  };
};

export const langToLocale = (lang: string) => {
  switch (lang) {
    case 'se':
    case 'sv':
      return 'sv-SE';
    case 'nb':
    case 'no':
      return 'nb-NO';
    case 'dk':
    case 'da':
      return 'da-DK';
    case 'fi':
      return 'fi-FI';
  }
  return '';
};

export const contextToSaveCustoms = (
  context: SendPackageContext,
  user?: User,
  countries?: ListCountry[]
): SaveCustomsRequest => ({
  csm_consignmentid: context.deliveryOption!.ConsignmentId,
  csm_customsadditionalinfo: '',
  csm_customsvalue: context.customs.value,
  csm_customsinvoicenbr: context.customs.invoiceNumber,
  csm_customsvaluecurrencyid: context.customs.currencyId ?? '',
  csm_customscountryoforiginid:
    countries?.find((x) => x.isocode === context.customs.countryOrigin)
      ?.countryid ?? '',
  csm_customsincoterms: '',
  csm_hscode1: context.customs.commodityCode,
  csm_hscode2: context.customs.commodityCode2,
  csm_hscode3: context.customs.commodityCode3,
  csm_hscode4: context.customs.commodityCode4,
  csm_hscode5: context.customs.commodityCode5,
  csm_hscode6: context.customs.commodityCode6,
  csm_eorinbrshipper: context.customs.shipperEori,
  csm_eorinbrconsignee: context.customs.consigneeEori,
});

export const contextToBookDef = (
  context: SendPackageContext,
  user?: User
): BookDefRequest => {
  const base: BookDefBase = {
    csm_paymentreference: context.paymentReference!,
    newwebbooking: 1,
    csm_consignmenttype: 2,

    con_save_addr: 0,
    shi_save_addr: 0,
    sendnewsletter: 0,

    csm_awbnbr: context.awbNumber,
    partnercompid: context.partnerInfo.partnerCompId,
    pac_contents: context.contents!,
    originalprice: context.deliveryOption!.OriginalPriceIncludingVAT.toString(),

    pdfreceiptemail: context.billingEmail,
    csm_insurancevalue: context.useInsurance
      ? context.insuranceValue
      : undefined,
  };

  const consignment: BookDefConsignment = {
    csm_consignmentid: context.deliveryOption!.ConsignmentId,
    pathfinderresult_pathfinderresultid: context!.deliveryOption!.AlternativeId,
    pathfinderresultid: context!.deliveryOption!.AlternativeId,
    is_courier: context.deliveryOption!.MethodOfTransport === 'Courier' ? 1 : 0,
    bookdefinitive:
      context.deliveryOption!.MethodOfTransport === 'Courier' ? undefined : 1,
  };

  const userDef: BookDefUser = {
    csm_customernbr: user?.customerNumber || '',
    csm_custorgid: user?.customerOrgId || '',
    csm_custpersonid: user?.customerPersonId || '',
    csm_orderpersonid: user?.customerPersonId || '',
    csm_paymentaccountnbr: user?.customerNumber || '',
    jui_pb: user?.partnerCompanyId || context.partnerInfo.partnerCompId,
    jui_u: user?.id || '',
    userid: user?.id || '',
  };

  const shipper: BookDefShipper = {
    csm_fromcityid: context.shipper.cityId,
    csm_fromcountryid: context.shipper.countryId,
    csm_pickupcontactfname:
      context.shipper.firstName ?? context.shipper.name ?? '',
    csm_pickupcontactsname: context.shipper.lastName ?? '',
    csm_pickupphone1: context.shipper.phone ?? '',
    shi_city: context.shipper.city,
    shi_countryid: context.shipper.countryId,
    shi_name:
      context.shipper.addressName &&
      context.shipper.addressName.trim().length > 0
        ? context.shipper.addressName
        : `${context.shipper.firstName} ${context.shipper.lastName}`,
    shi_postcode: context.shipper.postCode!,
    shi_streetaddress: context.shipper.street,
    shi_streetnbr: context.shipper.streetNumber,
    shi_addressid: context.shipper.id,
    csm_pickupmisc: context.shipper.message,
  };

  const consignee: BookDefConsignee = {
    con_streetaddress: context.consignee.street,
    con_streetnbr: context.consignee.streetNumber,
    con_city: context.consignee.city,
    con_countryid: context.consignee.countryId,
    con_name:
      context.consignee.addressName &&
      context.consignee.addressName.trim().length > 0
        ? context.consignee.addressName
        : `${context.consignee.firstName} ${context.consignee.lastName}`,
    con_postcode: context.consignee.postCode!,
    con_addressid: context.consignee.id,
    csm_tocityid: context.consignee.cityId,
    csm_tocountryid: context.consignee.countryId,
    csm_deliverycontactfname:
      context.consignee.firstName ?? context.consignee.name ?? '',
    csm_deliverycontactsname: context.consignee.lastName ?? '',
    csm_deliveryphone1: context.consignee.phone ?? '',
    csm_deliverymisc: context.consignee.message,
  };

  const payment: BookDefInvoice | BookDefCard =
    context.paymentType === 'card'
      ? {
          csm_paymentmethodid: PaymentMethodId.Card,
          referenceno: '', // requested by Anders Ö, Softronic
          paymentid: context.netsPaymentId,
        }
      : {
          csm_paymentmethodid: PaymentMethodId.Invoice,
          invoiceaddressid: '',
        };

  const customs =
    context.customsRequired &&
    ({
      isexport: 1,
      csm_customsvalue: context.customs.value,
      csm_customsinvoicenbr: context.customs.invoiceNumber,
      csm_customscommoditycode: context.customs.commodityCode,
      //csm_customscontents: context.customs.;
      csm_customsvaluecurrency: context.customs.currencyId,
      csm_customscountryoforiginid: context.customs.countryOrigin, // country id?
      csm_eorinbrshipper: context.customs.shipperEori,
      csm_eorinbrconsignee: context.customs.consigneeEori,
      csm_hscode1: context.customs.commodityCode,
      csm_hscode2: context.customs.commodityCode2,
      csm_hscode3: context.customs.commodityCode3,
      csm_hscode4: context.customs.commodityCode4,
      csm_hscode5: context.customs.commodityCode5,
      csm_hscode6: context.customs.commodityCode6,
    } as BookDefCustoms);

  const advise = context.advise.reduce(
    (acc, adv, i) => ({
      ...acc,
      [`AdviseType_${i}`]: adv.type,
      [`AdviseMethod_${i}`]: adv.method,
      [`Advise_Adr_${i}`]: adv.value,
    }),
    {} as BookDefAdvise
  );

  return {
    ...base,
    ...consignment,
    ...userDef,
    ...shipper,
    ...consignee,
    ...customs,
    ...payment,
    ...advise,
  };
};

export const loginResponseToUser = (response: LoginResponse): User => {
  return {
    // city: '',
    // customerNumber: '',
    // customerOrgId: '',
    // customerPersonId: '',
    firstName: response.firstname,
    hasOrg: false,
    id: response.userid,
    lastName: response.lastname,
    // orgName: '',
    // partnerCompanyId: '',
    // postcode: '',
    // streetAddressId: '',
    username: response.username,
    addresses: [],
    numAddress: 0,
    packageTypes: [],
  };
};

const sortByCity = sortByAddress('city');

export interface LoadUserResponse {
  user: Partial<User>;
}

export const customerInfoResponseToUser =
  (countries: ListCountry[]) =>
  ({
    userinfo,
    listaddress,
    packagetypes,
    numaddress,
  }: GetCustomerInfoResponse): LoadUserResponse => {
    const addresses = [...listaddress]
      .filter((a): a is GetCustomerInfoAddress => typeof a !== 'string')
      .map(customerInfoAddressToAddress(countries))
      .sort(sortByCity);
    const packageTypes = [...packagetypes]
      .filter((pt): pt is JenaPackagetype => typeof pt !== 'string')
      .map(
        (pt) =>
          ({
            ...pt,
            sortrank: pt.sortrank ?? 10,
            defaultheight: !!pt.defaultheight
              ? Number(pt.defaultheight)
              : undefined,
            defaultlength: !!pt.defaultlength
              ? Number(pt.defaultlength)
              : undefined,
            defaultweight: !!pt.defaultweight
              ? Number(pt.defaultweight)
              : undefined,
            defaultwidth: !!pt.defaultwidth
              ? Number(pt.defaultwidth)
              : undefined,
            maxheight: Number(pt.maxheight),
            maxlength: Number(pt.maxlength),
            maxweight: Number(pt.maxweight),
            maxwidth: Number(pt.maxwidth),
            translations: pt.translations
              .filter((t): t is Translation => typeof t !== 'string')
              .map((x) => ({
                language: countryToLanguage(x.language),
                translation: x.translation,
              })),
          } as Packagetype)
      );
    return {
      user: {
        id: userinfo.userid,
        city: userinfo.city,
        customerNumber: userinfo.customernbr,
        customerOrgId: userinfo.custorgid,
        customerPersonId: userinfo.custpersonid,
        firstName: userinfo.firstname,
        hasOrg: userinfo.hasorg === '1',
        lastName: userinfo.lastname,
        orgName: userinfo.orgname,
        partnerCompanyId: userinfo.partnercompid,
        postcode: userinfo.postcode,
        streetAddress: userinfo.streetaddress,
        streetNumber: userinfo.streetnbr,
        email: userinfo.email,
        phone: userinfo.phone,
        countryId: userinfo.countryid,
        numAddress: numaddress ? parseInt(numaddress) : addresses.length,
        addresses,
        packageTypes,
      },
    };
  };

export const customerInfoAddressToAddress =
  (countries: ListCountry[]) =>
  (address: GetCustomerInfoAddress): Address => {
    let firstName = address.contactpersonfname ?? '';
    let lastName = address.contactpersonsname ?? '';
    const spaceIndex = firstName.indexOf(' ');
    if (address.contactpersonsname === 'undefined' && spaceIndex > -1) {
      firstName = firstName.substring(0, spaceIndex);
      lastName = firstName.substring(spaceIndex + 1);
    }
    const country: string =
      countries.find((c) => c.countryid === address.countryid)?.name || '';
    const add: Address = {
      id: address.addressid,
      city: address.city,
      cityId: address.cityservedid,
      country,
      countryCode: address.isocode,
      countryId: address.countryid,
      message: '',
      name: `${firstName} ${lastName}`,
      phone: address.phone1,
      postCode: address.postcode,
      street: address.streetaddress,
      streetNumber: address.streetnbr,
      addressName: address.adrname,
      firstName,
      lastName,
    };
    return add;
  };

export const getShopToShop = (response: GetShopResponse): Shop => {
  const shop = response.shops.find(isShop);
  return {
    partnerCompId: shop!.partnercompid,
    method: shop!.method,
    shop: shop!.dibsshop,
  };
};

export const listcampaignToCampaign = (
  response: VerifyCampaignCodeResponse
): Campaign[] =>
  [...response.listcampaign]
    .filter(
      (lc): lc is ListCampaign =>
        typeof lc !== 'string' && 'novalidcampaign' in lc === false
    )
    .map(
      (lc: ListCampaign) =>
        ({
          alternatives: lc.alternatives
            .filter((a): a is ListCampaignAlternative => typeof a !== 'string')
            .map((a) => ({
              alternativeId: a.alternativeid,
              priceExVat: parseFloat(a.totalcsmprice),
              priceIncVat: parseFloat(a.totalcsmvat),
            })),
          campaignCpcId: lc.campaigncpcid,
          campaignCurrencyId: lc.campaigncurrencyid,
          campaignCurrency: lc.campaigncurrency,
          campaignDiscount: lc.campaigndiscount,
          campaignId: lc.cpid,
        } as Campaign)
    );

export const listAddressToAddress =
  (countries: ListCountry[]) =>
  (response: ListAddressResponse): Address[] =>
    [...response.listaddress]
      .filter((a): a is GetCustomerInfoAddress => typeof a !== 'string')
      .map(customerInfoAddressToAddress(countries));

export const listConsignmentToConsignments = (
  response: ListConsignmentResponse
): OrderHistoryRows => ({
  numrows: parseInt(response.numrows),
  listconsignment: [...response.listconsignment].filter(
    (lc): lc is ConsignmentResponse => typeof lc !== 'string'
  ),
});

export const contextToSaveUploadFiles = async (ctx: SendPackageContext) => {
  const promises: Promise<SaveUploadFileRequest>[] = [];
  if (ctx.files) {
    for (let i = 0; i < ctx.files.length; i++) {
      const file = ctx.files[i];
      promises.push(fileToSaveUploadFileRequest(file, ctx.requestId!));
    }
  }
  return Promise.all(promises);
};

const fileToSaveUploadFileRequest = async (
  file: File,
  requestId: string
): Promise<SaveUploadFileRequest> => {
  const filecontent = await fileToBase64(file);
  return {
    filecontent,
    filename: file.name,
    requestid: requestId,
  } as SaveUploadFileRequest;
};
