/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { useMachine } from '@xstate/react';
import useJenaApi from '../../hooks/useJenaApi';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import { useUser } from '../../hooks/useUser';
import orderHistoryMachine, {
  OrderHistoryContext,
} from './OrderHistoryMachine';
import { ConsignmentResponse } from '../../Models/jena/response/ListConsignmentResponse';
import { useState } from 'react';
import { IconChevronDown, IconChevronUp } from '../../svg';
import { format } from 'date-fns/fp';
import Address from '../../Models/Address';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import { verifyAddressRequest } from '../../mappers/jenaMappers';
import { DeliveryContext } from '../../Pages/Delivery/deliveryMachine';
import { Context, TEvents } from '../../Machines/sendPackageMachine';
import { OrderHistoryEvents } from './OrderHistoryEvents';
import { Button } from '../Base';
import Loader, { LoaderLabel } from '../Base/Loader';
import { FormSelect } from '../FormFields';

interface OrderHistoryColumn {
  key: keyof ConsignmentResponse;
  title: string;
}

const compareConsignmentResponse = (
  key: keyof ConsignmentResponse,
  asc: boolean
) => {
  return (a: ConsignmentResponse, b: ConsignmentResponse) => {
    return asc
      ? (a[key] || '').localeCompare(b[key] || '')
      : (b[key] || '').localeCompare(a[key] || '');
  };
};

const perPage = 40;

interface OrderHistoryProps {
  state: Context & DeliveryContext;
  send: (event: TEvents) => void;
  onClose: () => void;
}

const OrderHistory = (props: OrderHistoryProps) => {
  const api = useJenaApi();
  const { user } = useUser();
  const [state, send] = useMachine(orderHistoryMachine, {
    services: {
      searchEnhancedConsignment: (context: OrderHistoryContext, event: any) =>
        api.searchEnhancedConsignment({
          jui_u: user?.id,
          csm_bookedby: 0,
          // csm_status: 4,
          endrow: context.page * perPage,
          sortorder: 'LOCALDELIVERYDATETIME DESC',
          startrow: (context.page - 1) * perPage,
          csm_consignmenttype: 2,
          csm_bookingdate: !!context.bookingDate
            ? context.bookingDate
            : undefined,
          csm_customerreference: !!context.query ? context.query : undefined,
        }),
      showBookingInfo: (context: OrderHistoryContext, event: any) =>
        api.showBookingInfo({
          jui_u: user?.id,
          'track-n-trace': 1,
          awbnbr: context.awbNbr ?? '',
          awborcust: 1,
          language: 'GB',
        }),
      verify: ({ shipper, consignee }) =>
        api.verifyAddress(
          verifyAddressRequest(
            shipper || ({} as Address),
            consignee || ({} as Address)
          )
        ),
    },
  });
  const translation: Translations = useTranslation();
  const [currentKey, setCurrentKey] =
    useState<keyof ConsignmentResponse>('deliverydate');
  const [sortAsc, setSortAsc] = useState(true);
  const setSortBy = (key: keyof ConsignmentResponse) => {
    setSortAsc((v) => (currentKey === key ? !v : true));
    setCurrentKey(key);
  };

  const columns: OrderHistoryColumn[] = [
    { key: 'awbnbr', title: 'AWB' },
    { key: 'shipper', title: translation.shipper },
    { key: 'shippercity', title: translation.addressBookModal.city },
    { key: 'consignee', title: translation.consignee },
    { key: 'consigneecity', title: translation.addressBookModal.city },
    { key: 'packageqty', title: translation.quantity },
    { key: 'weightstr', title: translation.weight },
    { key: 'deliverydate', title: translation.delivery },
  ];
  const events = useBoundEvents(OrderHistoryEvents, send);

  return (
    <div>
      <h4 css={tw`mb-4`}>{translation.orderHistory.title}</h4>
      {state.matches('loading') && (
        <Loader variant="loading-delivery-alternatives">
          <LoaderLabel label={translation.loading} />
        </Loader>
      )}
      {state.matches('list') && (
        <>
          <div tw="flex items-center space-x-2">
            <div>
              {/* <FormInput
                name="search"
                value={state.context.query}
                onChange={(v) => events.changeQuery(v.toLocaleLowerCase())}
                placeholder={translation.orderHistory.search}
                onClear={() => events.resetQuery()}
              /> */}
            </div>
            <div>
              <FormSelect
                name="bookingDate"
                value={state.context.bookingDate}
                onChange={(v) => events.setBookingDate(v)}
                options={[
                  { label: translation.date, value: '' },
                  { label: translation.today, value: '1' },
                  { label: 'Yesterday', value: '2' },
                  { label: 'Past week', value: '3' },
                  { label: 'Past month', value: '4' },
                ]}
              />
            </div>
          </div>
          <table tw="w-full">
            <thead>
              <tr>
                {columns.map(({ key, title }) => (
                  <th
                    key={key}
                    tw="text-left px-3 pr-5 py-3 cursor-pointer relative hover:bg-gray-100"
                    onClick={() => setSortBy(key)}
                  >
                    {title}
                    {currentKey === key ? (
                      sortAsc ? (
                        <IconChevronDown tw="block absolute right-1 top-4 w-4 h-4" />
                      ) : (
                        <IconChevronUp tw="block absolute right-1 top-4 w-4 h-4" />
                      )
                    ) : (
                      <IconChevronDown tw="block text-gray-400 absolute right-1 top-4 w-4 h-4" />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {state.context.consignments
                ?.sort(compareConsignmentResponse(currentKey, sortAsc))
                .map((x) => {
                  const deliveryDate = new Date(
                    Date.parse(
                      !!x.reporteddate
                        ? `${x.reporteddate} ${x.reportedtime}`
                        : `${x.deliverydate} ${x.deliverytime}`
                    )
                  );
                  return (
                    <tr
                      key={`consignment-${x.consignmentid}`}
                      tw="p-3 border-b text-sm text-left hover:bg-gray-100 relative"
                    >
                      <td
                        tw="p-3 cursor-pointer"
                        onClick={() => events.showBooking(x.awbnbr)}
                      >
                        {x.awbnbr}
                      </td>
                      <td tw="p-3">{x.shipper}</td>
                      <td tw="p-3">{x.shippercity}</td>
                      <td tw="p-3">{x.consignee}</td>
                      <td tw="p-3">{x.consigneecity}</td>
                      <td>{x.packageqty}</td>
                      <td tw="p-3">{x.weightstr}kg</td>
                      <td tw="p-3">
                        {format('yyyy-MM-dd HH:mm')(deliveryDate)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div tw="flex items-center space-x-2 mt-4">
            <p>
              {translation.total}: {state.context.numRows}
            </p>
            <Button
              onClick={() => events.setPage(state.context.page - 1)}
              variant="outline"
              size="xs"
              disabled={state.context.page <= 1}
            >
              Previous
            </Button>
            <Button
              onClick={() => events.setPage(state.context.page + 1)}
              variant="outline"
              size="xs"
              disabled={state.context.numRows < state.context.page * perPage}
            >
              Next
            </Button>
          </div>
        </>
      )}
      {state.matches('detail') && (
        <div>
          <h2>{state.context.booking?.consignment?.awbnbr}</h2>
          <h3>{translation.pickUp}</h3>
          <address>
            {state.context.booking?.pickupaddress.streetaddress}{' '}
            {state.context.booking?.pickupaddress.streetnbr}
            <br />
            {state.context.booking?.pickupaddress.postcode}{' '}
            {state.context.booking?.pickupaddress.city}
          </address>
          <h3>{translation.delivery}</h3>
          <address>
            {state.context.booking?.deliveryaddress.streetaddress}{' '}
            {state.context.booking?.deliveryaddress.streetnbr}
            <br />
            {state.context.booking?.deliveryaddress.postcode}{' '}
            {state.context.booking?.deliveryaddress.city}
          </address>
          <div tw="flex justify-between">
            <Button
              variant="primary"
              onClick={() => {
                props.send({
                  type: 'COPY_BOOKING',
                  data: state.context!.booking!,
                });
                props.onClose();
              }}
            >
              Copy
            </Button>
            <Button variant="secondary" onClick={() => events.list()}>
              Back
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
